.TabbedMaps .tabbed-maps-panel {
  border-radius: 3px;
  border: 1px solid #dee2e6;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.TabbedMaps .tab-content {
  background-color: #ffffff;
  padding: 1rem 1.5rem;
  border-radius: 0 0 3px 3px;
}

.TabbedMaps .nav-tabs {
  border-radius: 3px 3px 0 0;
}

.TabbedMaps .mobile-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-transform: capitalize;

  color: #2A2A2A;
}

.TabbedMaps .navlink {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;

  padding-top: 5px;
  margin-top: 20px;
  color: #FFFFFF !important;
  border-top: 5px solid transparent;
  border-bottom: 1px solid transparent;
}

@media (max-width: 812px) { 
  .TabbedMaps {
    background-color: #ffffff;
    padding: .7rem;
    border-radius: 0 0 3px 3px;
  }

  .TabbedMaps .tabbed-maps-panel {
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  .TabbedMaps .tab-content {
    background-color: #ffffff;
    padding: 0;
    border-radius: 0;
  }
}