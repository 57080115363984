.ChargingStationsMap .map-container {
  position: relative;
  height: 552px;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  display: flex;
}

.ChargingStationsMap .map-container .gm-style{
  display: none;
}

.ChargingStationsMap .map-container .gm-style.poi-info-window{
  display: block;
}




.title {
  font-size: 14px;
  margin-bottom: 1rem;
  font-weight: bold;
}

input[type="checkbox"]:focus{
  box-shadow: none;
}