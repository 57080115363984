h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-weight: 500;
}

a:focus,
button:focus,
.btn:focus,
.form-control:focus,
input:focus,
select:focus,
textarea:focus,
.image-gallery-thumbnail:focus,
.SimpleCopyPageBody .card:focus,
.evc-card:focus,
.evc-card.selected,
.evc-card.selected:hover,
.SocialMediaShareButton:focus {
  box-shadow: 0 0 0 4px #0089c4;
  outline: 0;
}

.PageLayout {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.PageLayout .main-content-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.PageLayout .page-specific-content-container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.PageLayout .Header,
.PageLayout .Footer,
.PageLayout .copyright {
  flex-shrink: 0;
}

.PageLayout .Header a,
.PageLayout .Footer a {
  color: white;
  text-decoration: none;
}

.PageLayout a:hover {
  text-decoration: underline;
}

.PageLayout .section-white-background {
  background-color: white;
}

section.container,
section.container-fluid {
  padding: 60px 15px 20px 15px;
}

section.container .container,
section.container-fluid .container {
  padding-left: 0;
  padding-right: 0;
}

.PageLayout .copyright {
  padding: 40px 0;
  background-color: #031a2d;
  color: #848b93;
  font-size: 14px;
  text-align: center;
}

@media print {
  .PageLayout .copyright {
    padding: 10px 0;
  }
}

.sticky {
  position: fixed;
  position: sticky;
  position: -webkit-sticky;
  top: 40%;
}

.hide-offscreen {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
}

.clear-bottom-margin {
  margin-bottom: 0 !important;
}
.small-bottom-margin {
  margin-bottom: 5px !important;
}

.legal-disclaimer {
  font-size: 10px;
  letter-spacing: 0;
  color: #666666;
}

.renderRowOfData p span {
  float: right;
  font-weight: 500;
}

.h3 strong,
.h4 strong,
.h6 strong {
  color: #218739 !important;
}

.SimpleCopyPageHeader {
  color: #333333;
}

.SimpleCopyPageBody {
  padding-bottom: 75px;
}

.SimpleCopyPageBody h1 {
  margin-top: 60px;
  color: #333333;
}

.SimpleCopyPageBody p.h4 {
  color: #333333;
  margin-top: 30px;
  font-weight: 500;
}

.SimpleCopyPageBody p {
  color: #052136;
  letter-spacing: 0;
  line-height: 26px;
}

a.supporting-link {
  color: #08679d;
  margin-top: 0;
  font-weight: 700;
  font-size: 12px;
}

.SimpleCopyPageBody .card {
  background-color: white;
}

.SimpleCopyPageBody .card:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
}

.SimpleCopyPageBody .card:-webkit-any-link:hover,
.SimpleCopyPageBody .card a:hover {
  color: #333333;
  text-decoration: none;
}

/* evc-card */

.evc-card {
  background-color: white;
  font-size: 14px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  color: #333333;
  width: 100%;
  display: inline-block;
  cursor: pointer;
}

.evc-card.not-selected {
  height: 220px;
}

a.evc-card:hover,
.evc-card:hover {
  border: 1px solid white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  color: #333333;
  text-decoration: none;
}

/* Card Layouts */

.render-cards-container {
  display: flex;
  flex-wrap: wrap;
}

.render-cards-container .evc-card {
  width: 48%;
  margin: 1%;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  section.container,
  section.container-fluid {
    padding: 60px 15px 20px 50px;
  }

  .render-cards-container .evc-card {
    width: 49%;
    margin: 1%;
  }

  .render-cards-container > *:nth-of-type(2n) {
    margin-right: 0;
  }

  .render-cards-container > *:nth-of-type(2n + 1) {
    margin-left: 0;
  }

  .render-cards-container > *:first-child,
  .render-cards-container > *:nth-child(2) {
    margin-top: 0;
  }

  .render-cards-container.full-width .evc-card {
    width: 23.5%;
    margin: 1%;
  }

  .render-cards-container.full-width > *:nth-of-type(4n) {
    margin-right: 0;
  }
  .render-cards-container.full-width > *:nth-of-type(4n + 1) {
    margin-left: 0;
  }
  /* .render-cards-container.full-width.for-homepage .evc-card:nth-child(4) {
    display: block;
  } */
}

@media (min-width: 1200px) {
  .evc-card.not-selected {
    height: 250px;
  }

  .render-cards-container .evc-card {
    width: 32%;
    margin: 1%;
  }

  .render-cards-container > *:nth-of-type(3n) {
    margin-right: 0;
  }

  .render-cards-container > *:nth-of-type(3n + 1) {
    margin-left: 0;
  }

  .render-cards-container > *:first-child,
  .render-cards-container > *:nth-child(2),
  .render-cards-container > *:nth-child(3) {
    margin-top: 0;
  }
}
