$alert-border-radius: 0;
$alert-bg-level: 0;
$alert-border-level: 0;
$alert-color-level: 10;

$red: #D13D3D;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 813px,
  xl: 1200px,
  xxl: 1900px
);

@import '~bootstrap/scss/bootstrap.scss';

.alert-danger {
  color: #ffffff;

  a {
    color: #ffffff;
    text-decoration: underline;
  }
}