.SelectedChargingStationCard {
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 15px;
  font-size: 0.75rem;
  display: flex;
}

.SelectedChargingStationCard .card-left{
 align-self: center;
}
.SelectedChargingStationCard .card-right{
  margin-left: 15px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;  
  text-align: left;
}
.SelectedChargingStationCard .card-right p{
 margin: -5px 0px;
}
.SelectedChargingStationCard .card-right .card-hyperlink{
 margin-top: 10px;
}
.SelectedChargingStationCard .card-right p:first-child{
 margin-bottom: 10px;
 font-weight: bold;
}


.SelectedChargingStationCard .card-icon {
  width: 33px;
}

@media (max-width: 812px) {
  .SelectedChargingStationCard {
    width: 100% !important;
  }
}