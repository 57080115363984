.ChargingStationsMapLegend {
  background: #f8f8f6;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
  font-size: 0.75rem;
}

.ChargingStationsMapLegend .charger-type {
  position: relative;
  padding-right: 2rem;
  margin-bottom: 1rem;
}

.ChargingStationsMapLegend .charger-type:last-child {
  margin-bottom: 0;
}

.ChargingStationsMapLegend .charger-type img {
  width: 25px;
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 812px) {
  .ChargingStationsMapLegend {
    margin-bottom: 2rem;
  }
}
