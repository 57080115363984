.nav-tabs {
  background-color: #000000;
  padding: 0 1.5rem;
}

.nav-tabs .nav-link {
  cursor: pointer;
  border-radius: 0;
  padding: 1.5rem 0;
  font-weight: bold;
  margin: 0 1rem;
}

.nav-tabs .nav-item:first-child .nav-link {
  margin-left: 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    background-color: #000000 ;
    border-color: transparent;
    border-top: solid 5px #ffffff;
    color: #ffffff !important;
    padding-top: 5px;
    margin-top: 20px;
    border-bottom: 1px solid #dee2e6;
}

