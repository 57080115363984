.ZipcodeInputWithButton .label-title {
    font-weight: 700;
    font-size: 12px;
    color: #000000;
    margin-bottom: 10px;
}

.ZipcodeInputWithButton .label-subTitle {
    text-transform: capitalize;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #2A2A2A;
    margin-bottom: 8px;
}
.ZipcodeInputWithButton .form-control {
    font-size: 12px;
}
