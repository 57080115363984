.DealersMap .map-container {
  position: relative;
  height: 552px;
  width: 100%;
}

.DealersMap .map-controls {
  display: flex;
  flex-direction: column;
}

.DealersMap .map-controls .controls {
  flex-grow: 1;
}

.DealersMap .DealersList {
  margin-top: 1em;
  text-align: center;
}
