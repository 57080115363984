.TravelRouteMap .map-container {
  position: relative;
  height: 552px;
  width: 100%;
}

.TravelRouteMap label {  
  text-transform: capitalize;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #2A2A2A;
  margin-bottom: 8px;
}

.title {
  font-size: 14px;
  margin-bottom: 1rem;
  font-weight: bold;
}

.TravelRouteMap .travel-route-map-input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.TravelRouteMap .travel-route-map-icon {
  width: 16.8px;
  height: 24px;
  margin: 0 16px 0 0;
}

input[type="checkbox"]:focus{
  box-shadow: none;
}