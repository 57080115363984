.MapControlPanel {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.MapControlPanel .SelectedChargingStationCard {
  margin-bottom: 10px;
}

.MapControlPanel .form-check label {
  font-weight: 700;
  font-size: 12px;
  color: #666666;
  text-transform: uppercase;
  margin-bottom: 0;
}
