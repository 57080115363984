@font-face {
  font-family: din;
  src: url("./../../assets/fonts/din/DINOT_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-weight: 900;
  src: url("./../../assets/fonts/din/DINOT-Black_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-weight: 900;
  font-style: italic;
  src: url("./../../assets/fonts/din/DINOT-BlackItalic_0.otf")
    format("opentype");
}

@font-face {
  font-family: din;
  font-weight: 700;
  src: url("./../../assets/fonts/din/DINOT-Bold_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-weight: 700;
  font-style: italic;
  src: url("./../../assets/fonts/din/DINOT-BoldItalic_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-stretch: condensed;
  src: url("./../../assets/fonts/din/DINOT-Cond_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-stretch: condensed;
  font-weight: 900;
  src: url("./../../assets/fonts/din/DINOT-CondBlack_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-stretch: condensed;
  font-weight: 900;
  font-style: italic;
  src: url("./../../assets/fonts/din/DINOT-CondBlackIta_0.otf")
    format("opentype");
}

@font-face {
  font-family: din;
  font-stretch: condensed;
  font-weight: 700;
  src: url("./../../assets/fonts/din/DINOT-CondBold_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-stretch: condensed;
  font-weight: 700;
  font-style: italic;
  src: url("./../../assets/fonts/din/DINOT-CondBoldIta_0.otf")
    format("opentype");
}

@font-face {
  font-family: din;
  font-stretch: condensed;
  font-style: italic;
  src: url("./../../assets/fonts/din/DINOT-CondIta_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-stretch: condensed;
  font-weight: 200;
  src: url("./../../assets/fonts/din/DINOT-CondLight_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-stretch: condensed;
  font-weight: 200;
  font-style: italic;
  src: url("./../../assets/fonts/din/DINOT-CondLightIta_0.otf")
    format("opentype");
}

@font-face {
  font-family: din;
  font-stretch: condensed;
  font-weight: 500;
  font-style: italic;
  src: url("./../../assets/fonts/din/DINOT-CondMediIta_0.otf")
    format("opentype");
}

@font-face {
  font-family: din;
  font-stretch: condensed;
  font-weight: 500;
  src: url("./../../assets/fonts/din/DINOT-CondMedium_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-style: italic;
  src: url("./../../assets/fonts/din/DINOT-Italic_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-weight: 200;
  src: url("./../../assets/fonts/din/DINOT-Light_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-weight: 200;
  font-style: italic;
  src: url("./../../assets/fonts/din/DINOT-LightItalic_0.otf")
    format("opentype");
}

@font-face {
  font-family: din;
  font-weight: 500;
  src: url("./../../assets/fonts/din/DINOT-Medium_0.otf") format("opentype");
}

@font-face {
  font-family: din;
  font-weight: 500;
  font-style: italic;
  src: url("./../../assets/fonts/din/DINOT-MediumItalic_0.otf")
    format("opentype");
}

/* Backup: Alte Din */
/* Source: https://www.1001fonts.com/alte-din-1451-mittelschrift-font.html */

@font-face {
  font-family: alte-din;
  src: url("./../../assets/fonts/alte-din/din1451alt.ttf") format("truetype");
}

@font-face {
  font-family: alte-din;
  font-weight: 700;
  src: url("./../../assets/fonts/alte-din/din1451altbold.ttf")
    format("truetype");
}

@font-face {
  font-family: Kia Signature OTF;
  font-weight: 500;
  src: url("./../../assets/fonts/kia/KiaSignatureOTFRegular.otf")
    format("opentype");
}

@font-face {
  font-family: Kia Signature OTF;
  font-weight: 300;
  src: url("./../../assets/fonts/kia/KiaSignatureOTFLight.otf")
    format("opentype");
}

@font-face {
  font-family: Kia Signature OTF;
  font-weight: 700;
  src: url("./../../assets/fonts/kia/KiaSignatureOTFBold.otf")
    format("opentype");
}

/* Global EVC Styles */

body {
  background-color: #f2f2f2;
  font-family: Kia Signature OTF, sans-serif;
}

a {
  color: #07679b;
}

table tbody th {
  font-weight: normal;
}

table tbody tr th:first-child,
table tfoot tr th:first-child {
  text-align: left;
}

table.table-bordered tfoot td,
table.table-bordered tfoot th {
  font-weight: bold;
  border-top: 2px solid #dee2e6;
}

button.btn-sm,
.input-well .button.btn-sm {
  font-size: 80%;
}

.modal-backdrop.show {
  background-color: #052136;
  opacity: 0.85;
}

.btn-kia {
  background: #000000;
  border: 2px solid #000000;
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
}

a.btn-kia:hover {
  text-decoration: none !important;
  color: #052136;
}

.btn-kia:disabled {
  background: #f2f2f2 !important;
  border: 2px solid #dbdbdb !important;
  color: #555;
  cursor: default;
}

.btn-default {
  border: 1px solid #ced4da;
}

.btn-kia-alt {
  background: #ffffff !important;
  border: 1px solid #ced4da !important;
  border-radius: 3px;
  color: #07679b !important;
  padding: 16px;
  font-weight: bold !important;
  cursor: pointer;
}

.btn-kia-alt:hover, a.btn-kia-alt:hover {
  /*text-decoration: none !important;*/
  text-decoration: underline;
  color: #052136;
}

.btn-kia-alt:disabled {
  background: #f2f2f2;
  border: 1px solid #dbdbdb !important;
  color: #555;
  cursor: default;
}

strong {
  font-weight: 700;
}

.arrow-up {
  top: calc((1rem - 1px) * -1);
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 0 0.3rem;
}

.arrow-up:before,
.arrow-up:after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.arrow-up:before {
  top: 0;
  border-width: 0 1rem 1rem 1rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.arrow-up:after {
  top: 1px;
  border-width: 0 1rem 1rem 1rem;
  border-bottom-color: #fff;
}

.input-well {
  background-color: white;
  padding: 25px;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
}

.input-well .form-group {
  margin-bottom: 1.5rem;
}

.input-well form .form-group:last-child {
  margin-bottom: 0;
}

.input-well .form-group p,
.form-group p {
  margin-top: 8px;
  margin-bottom: 1rem;
}

.input-well label,
.input-well .label-style {
  font-weight: 700;
  font-size: 12px;
  color: #666666;
  text-transform: uppercase;
  margin-bottom: 0;
}

.input-well .btn {
  background-color: #f2f2f2;
  display: inline-block;
  border: 1px solid #dbdbdb;
  font-weight: 400;
}

.input-well .btn.active {
  background-color: #faba45;
  border: 1px solid #faba45;
}

.input-well .btn-block-container .btn {
  display: block;
  width: 100%;
  margin-bottom: 6px;
  text-align: left;
}

.input-well .btn-block-container .btn .badge {
  background-color: white;
  border: 1px solid #dbdbdb;
  margin-right: 5px;
}

.input-well .btn-block-container .btn .badge img {
  width: 20px;
}

.input-well .btn-grid-container {
  display: flex;
  flex-wrap: wrap;
}

.input-well .btn-grid-container button {
  width: 48%;
  margin: 2%;
}

.input-well .btn-grid-container button:nth-of-type(2n) {
  margin-right: 0;
}

.input-well .btn-grid-container button:nth-of-type(2n + 1) {
  margin-left: 0;
}

.form-check-input {
  margin-top: 0.45rem;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: bottom 0.5625rem right 0.5625rem;
}
