.container {
  max-width: 100%;
  width: 1271px;

  max-height: 741px;
  height: 741px;

  padding: 0;
}

@media (max-width: 1024px) {
  .container {
    max-width: 100%;
    width: 908px;

    max-height: 741px;
    height: 741px;
  }
}

@media (max-width: 812px) {
  .container {
    max-width: 100%;
    width: 749px;

    max-height: 916px;
    height: 916px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
    width: 640px;

    max-height: 1053px;
    height: 1053px;
  }
}

@media (max-width: 375px) {
  .container {
    max-width: 100%;
    width: 313px;

    max-height: 1025px;
    height: 1025px;
  }
}